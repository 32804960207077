<!-- 框架 -->
<template>
  <div class="box">
    <!-- <el-scrollbar style="height: 100%"> -->
    <div style="position: relative">
      <div :class="activeIndex == '/home' ? 'div_title' : 'div_title_son'">
        <img :src="logo" alt @click="go_home" />
        <el-menu
          :default-active="activeIndex"
          :class="activeIndex == '/home' ? 'menu_f' : 'menu_f_son'"
          mode="horizontal"
          :text-color="activeIndex == 'home' ? '#fff' : '#000'"
          :active-text-color="activeIndex == '/home' ? '#fff' : '	#409EFF'"
          @select="change_aside_menu"
        >
          <el-menu-item index="/home">首页</el-menu-item>
          <el-menu-item index="/product" @click="product"
            >产品&解决方案</el-menu-item
          >
          <el-menu-item index="/journalism">新闻资讯</el-menu-item>
          <el-menu-item index="/contactUs">联系我们</el-menu-item>
          <el-menu-item index="/AboutUs">关于我们</el-menu-item>
        </el-menu>
      </div>
      <div :class="activeIndex == '/home' ? 'touch-nav' : 'touch-nav_son'">
        <div class="touch-head">
          <div class="logo">
            <img :src="logo" alt />
          </div>
          <div class="touch-toggle">
            <span
              class="el-icon-s-unfold"
              v-if="isShow"
              @click="navList"
            ></span>
            <span class="el-icon-s-fold" v-if="!isShow" @click="navList"></span>
          </div>
        </div>
      </div>
      <el-drawer :visible.sync="isShow" size="50%">
        <span>
          <span class="logo_span">
            <img src="../assets/logo.png" alt />
          </span>
          <ul class="nav-list" v-show="isShow">
            <li>
              <router-link :to="{ path: '/home' }">
                <div>
                  <i class="el-icon-s-home"></i>
                  <span>首页</span>
                </div>
              </router-link>
            </li>
            <li>
              <router-link :to="{ path: '/product' }">
                <div>
                  <i class="el-icon-s-management"></i>
                  <span>产品&解决方案</span>
                </div>
              </router-link>
            </li>
            <li>
              <router-link :to="{ path: '/journalism' }">
                <div>
                  <i class="el-icon-s-order"></i>
                  <span>新闻资讯</span>
                </div>
              </router-link>
            </li>
            <li>
              <router-link :to="{ path: '/contactUs' }">
                <div>
                  <i class="el-icon-phone"></i>
                  <span>联系我们</span>
                </div>
              </router-link>
            </li>
            <li>
              <router-link :to="{ path: '/AboutUs' }">
                <div>
                  <i class="el-icon-user-solid"></i>
                  <span>关于我们</span>
                </div>
              </router-link>
            </li>
          </ul>
        </span>
      </el-drawer>
      <router-view ref="productView"></router-view>
      <div class="foot">
        <!-- <div class="foot_content">
            <p style="margin-bottom: 30px; color: #8a9499">您想了解</p>
            <div style="position: relative">
              <span v-for="(item, index) in link_list" :key="index">
                {{
                item.name
                }}
              </span>
              <div class="foot_span">
                <span
                  v-for="(item, index) in link_list1"
                  :key="index"
                  @click="click_bottom(item.id)"
                >
                  {{
                  item.name
                  }}
                </span>
              </div>
            </div>
        </div>-->
        <div style="border-bottom: 1px solid #fff"></div>
        <p style="text-align: center; color: #8a9499; margin-top: 15px">
          版权所有 &nbsp; © 2022 &nbsp; 天朗易从科技(北京)有限公司&nbsp;
          <span style="color: #8a9499; cursor: pointer" @click="opan_a"
            >京ICP备20026871号-2</span
          >
          <!-- https://beian.miit.gov.cn/#/Integrated/index -->
        </p>
      </div>
    </div>
    <!-- </el-scrollbar> -->
  </div>
</template>

<script>
import logoHome from "../assets/logoHome.png";
import logo from "../assets/logo.png";
export default {
  data() {
    return {
      activeIndex: "/home",
      link_list: [
        // {
        //   name: "友情链接",
        // },
        // {
        //   name: "友情链接",
        // },
        // {
        //   name: "友情链接",
        // },
        // {
        //   name: "友情链接",
        // },
      ],
      link_list1: [
        // {
        //   id: 1,
        //   name: "联系我们",
        // },
        // {
        //   id: 2,
        //   name: "客服",
        // },
        // {
        //   id: 3,
        //   name: "免责声明",
        // },
        // {
        //   id: 4,
        //   name: "隐私政策",
        // },
      ],
      isShow: false,
      logo: "",
    };
  },

  components: {},
  watch: {
    $route(to, from) {
      this.activeIndex = to.path;
      this.isShow = false;
      if (to.path == "/home") {
        this.logo = logoHome;
      } else {
        this.logo = logo;
      }
    },
  },

  mounted () {
    _hmt.push(['_trackEvent', '官网', '进入']);
    this.init();
    this.activeIndex =
      this.$route.name == "home" ? "/home" : "/" + this.$route.name;
    if (this.$route.name == "CaseDetails") {
      this.activeIndex = "/product";
    }
  },
  methods: {
    // 点击底部右侧
    click_bottom(id) {
      // 1 联系我们
      if (id == 1) {
        this.$router.push("/contactUs");
      }
    },
    opan_a() {
      window.open("https://beian.miit.gov.cn/#/Integrated/index");
    },
    go_home() {
      if (this.$route.path == "/home") {
        return;
      }
      this.$router.push("/home");
    },
    init() {
      this.activeIndex = this.$route.path;
      if (this.activeIndex == "/home") {
        this.logo = logoHome;
      } else {
        this.logo = logo;
      }
      this.$forceUpdate();
    },
    navList() {
      this.isShow = !this.isShow;
      return this.isShow;
    },
    change_aside_menu(index) {
      this.$router.push(index);
      this.activeIndex = index;
      if (this.activeIndex == "/home") {
        this.logo = logoHome;
        this.$forceUpdate();
      } else {
        this.logo = logo;
        this.$forceUpdate();
      }
    },
    product() {
      if (this.$route.name == "product") {
        this.$refs.productView.isShow = true;
      }
    },
  },
};
</script>
<style lang='less' scoped>
.box {
  width: 100%;
  height: 100%;
}

.div_title {
  display: none !important;
}
.div_title_son {
  display: none !important;
  background-color: #fff;
}
.touch-nav {
  display: none !important;
}
.touch-nav_son {
  display: none !important;
  background-color: #fff;
}
/deep/.el-icon-s-unfold {
  display: inline-block;
  margin-left: 16 * 5px;
  margin-top: 16 * 5px;
  font-size: 34 * 5px;
  margin-right: 15 * 5px;
  color: #fff;
}
/deep/.el-icon-s-fold {
  display: inline-block;
  margin-left: 16 * 5px;
  margin-top: 16 * 5px;
  font-size: 34 * 5px;
  margin-right: 15 * 5px;
  color: #fff;
}
@media screen and (min-width: 1024px) {
  .touch-nav {
    display: none !important;
  }
  .touch-nav_son {
    display: none !important;
  }
  .div_title {
    position: absolute;
    left: 5%;
    top: 0;
    height: 70px;
    width: 90%;

    // position: relative;
    display: block !important;
    margin: 0 auto;
    .menu_f {
      // width: 50%;
      display: inline-block;
      position: absolute;
      right: 10%;
      top: 0;
      background-color: rgba(0, 0, 0, 0);
      border-bottom: none;
      color: #fff;
      .el-menu-item {
        padding: 0 20px;
        margin-right: 40px;
      }
    }
    .menu_f:hover {
      background-color: rgba(0, 0, 0, 0);
      // background-color: red;
    }
    .el-menu-item:hover {
      background-color: rgba(0, 0, 0, 0);
    }

    img {
      width: 130px;
      margin: 15px 0 0 12%;
      display: inline-block;
    }
    /deep/.el-menu-item {
      color: #fff !important;
    }
  }
  .div_title_son {
    // position: absolute;
    left: 0;
    top: 0;
    height: 70px;
    width: 90%;
    margin: 0 auto;
    position: relative;
    display: block !important;
    .menu_f_son {
      // width: 50%;
      display: inline-block;
      position: absolute;
      right: 10%;
      top: 0;
      background-color: rgba(0, 0, 0, 0);
      border-bottom: none;
      color: #fff;
      .el-menu-item {
        padding: 0 20px;
        margin-right: 40px;
      }
    }
    img {
      width: 130px;
      margin: 15px 0 0 12%;
      display: inline-block;
    }
  }
  .foot {
    color: #fff;
    width: 100%;
    // height: 260px;
    height: 60px;
    // position: relative;
    background-color: #1f2a34;
    .foot_content {
      // transform: translate(20%);
      padding-top: 30px;
      width: 70%;
      height: 70%;
      margin: 0 auto;
      // position: relative;
      .foot_span {
        position: absolute;
        right: 0;
        top: 0;
      }
      span {
        color: #fff;
        margin-right: 20px;
      }
    }
  }
}
@media screen and (max-width: 1023px) {
  /deep/.el-icon-s-unfold {
    color: #000;
  }
  /deep/.el-icon-s-fold {
    color: #000;
  }
  .div_title {
    display: none !important;
  }
  .div_title_son {
    display: none !important;
  }
  .div_title {
    display: none !important;
  }
  .div_title_son {
    display: none !important;
  }
  .touch-head {
    display: flex;
    // width: 108 * 5px;
    // height: 35 * 5px;
    justify-content: space-between;
    .logo {
      display: inline-block;
      margin-left: 16 * 5px;
      margin-top: 16 * 5px;
      img {
        width: 108 * 5px;
        height: 35 * 5px;
      }
    }

    .touch-toggle {
      // width: 108 * 5px;
      // height: 35 * 5px;
      display: inline-block;
      line-height: 35 * 5px;
      // margin: 0 !important;
    }
  }

  .touch-nav {
    display: block !important;
    position: absolute;
    height: 56 * 5px;
    width: 100%;
    .nav-list {
      background: #3aa0d8;
      background-color: #fff;
      position: absolute;
      width: 301.34 * 5px;
      height: 895.48 * 5px;
      top: 53.68 * 5px;
      // right: 0;
      padding-left: 0;
      left: 156.16 * 5px;
      z-index: 100;

      li {
        height: 53.68 * 5px;
        top: 136.64 * 5px;
        left: 156.16 * 5px;
        a {
          text-decoration: none;
          color: #056bc6;
          span {
            border-bottom: 1px #fff solid;
            margin: 0 40 * 5px;
            display: block;
            line-height: 53.68 * 5px;
          }
        }

        .router-link-exact-active {
          div {
            background-color: #0269ad !important;
          }
        }
      }
      li:last-child {
        border-bottom: none;
      }
    }
  }
  .touch-nav_son {
    display: block !important;
    // position: absolute;
    // height: 391px;
    width: 100%;
    .nav-list {
      background: #3aa0d8;
      background-color: #fff;
      position: absolute;
      width: 301.34 * 5px;
      height: 895.48 * 5px;
      top: 53.68 * 5px;
      left: 156.16 * 5px;
      padding-left: 0;
      z-index: 100;

      li {
        height: 53.68 * 5px;
        top: 136.64 * 5px;
        left: 156.16 * 5px;
        a {
          text-decoration: none;
          color: #056bc6;
          span {
            border-bottom: 1px #fff solid;
            margin: 0 40 * 5px;
            display: block;
            line-height: 53.68 * 5px;
          }
        }

        .router-link-exact-active {
          div {
            background-color: #0269ad !important;
          }
        }
      }
      li:last-child {
        border-bottom: none;
      }
    }
  }
  /deep/.el-drawer {
    border-radius: 10 * 5px 0 0 10 * 5px;
    .el-drawer__close-btn {
      display: none;
    }
    .logo_span {
      display: inline-block;
      width: 50%;
      margin-left: 20 * 5px;
      img {
        width: 100%;
      }
    }
    .nav-list {
      background: #3aa0d8;
      background-color: #fff;
      // position: absolute;
      // width: 301.34 * 5px;
      // height: 895.48 * 5px;
      // height: 100%;
      // top: 53.68 * 5px;
      // top: 0;
      // right: 0;
      padding-left: 0;
      // left: 156.16 * 5px;
      z-index: 100;

      li {
        height: 53.68 * 5px;
        top: 136.64 * 5px;
        left: 156.16 * 5px;
        div {
          font-size: 16 * 5px;
          position: relative;
          i {
            position: absolute;
            top: 49%;
            left: 20 * 5px;
            transform: translate(0, -50%);
          }
        }
        a {
          text-decoration: none;
          color: #056bc6;
          span {
            border-bottom: 1px #fff solid;
            margin: 0 40 * 5px;
            // font-size: 16 * 5px;
            display: block;
            line-height: 53.68 * 5px;
          }
        }

        .router-link-exact-active {
          div {
            background-color: #0269ad !important;
          }
        }
      }
      li:last-child {
        border-bottom: none;
      }
    }
  }

  .foot {
    background-color: #fff;
    height: 34 * 5px;
    margin-top: 20 * 5px;
    // position: absolute;
    // bottom: 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    p {
      display: none;
    }
  }
  /deep/.router-link-exact-active {
    color: #fff !important;
  }
}
/deep/.el-scrollbar__wrap {
  overflow-x: auto;
}
/deep/.is-active {
  background-color: rgba(0, 0, 0, 0) !important;
}
/deep/.el-menu-item {
  background-color: rgba(0, 0, 0, 0) !important;
}
/deep/.el-menu-item:hover {
  background-color: rgba(0, 0, 0, 0) !important;
}
</style>
